form {
  fieldset {
    margin: 15px 0;
    padding: 15px;

    font-size: $input-label-font-size;

    border: 1px solid $input-border-color;
    border-radius: 5px;

    legend {
      padding: 0 5px;
    }
  }

  .select,
  .ginput_container_select {
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 20px;

      font-size: 16px;
      font-family: 'Font awesome 5 Pro';

      transform: translate(0, -50%);

      content: '\f078';
      pointer-events: none;
    }

    select:hover {
      cursor: pointer;
    }

    select:disabled {
      opacity: 0.5;
    }
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 100px;

    line-height: 1.4;

    appearance: none;
    resize: none;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:focus {
      border-color: $input-active-color;
      outline: none;
    }
  }
}
