@each $color, $tint in $theme-colors {
	@each $i, $value in $tint {
		@if ($i == "default") {
			.color-#{"" + $color} {
				color: $value;
			}

			.background-#{"" + $color} {
				background-color: $value;
			}
		}

		@else {
			.color-#{"" + $color}-#{$i} {
				color: $value;
			}

			.background-#{"" + $color}-#{$i} {
				background-color: $value;
			}
		}
	}
}
