.btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	display: inline-block;
	width: auto;

	padding: $button-padding;
	overflow: visible;

	color: inherit;

	font-size: $button-font-size;
	font-family: $font-family-base;
	line-height: 1.4;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: middle;

	background: none;
	border-color: transparent;
	border-style: solid;
	border-width: 1px;

	border-radius: $button-border-radius;

	transition-property: background-color, color;

	appearance: none;

	@include transition(all);

	&:focus {
		outline: none;
	}

	&:focus,
	&:hover {
		cursor: pointer;
	}

	&:active {
		opacity: 1;
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			cursor: not-allowed;
		}
	}
}

.btn-row {
	display: flex;
	justify-content: space-between;
	margin-top: px(4);

	a:last-child,
	button:last-child,
	input[type="submit"]:last-child {
		margin-left: auto;
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;

		a,
		button,
		input[type="submit"] {
			width: 100%;
			margin-top: px(1);
			justify-content: center;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}
