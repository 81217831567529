svg {
  max-height: 100%;
}

figure,
.figure {
  max-width: 100%;
  margin: 0 0 $image-margin-bottom 0;

  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    padding-top: 3px;

    font-size: $font-size-sm;
    font-style: italic;
  }
}
