html {
  color: $body-color;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  font-family: $font-family-base;
  line-height: $line-height-base;
}

@include media-breakpoint-down(sm) {
  .hero {
    font-size: $font-size-xl;
    line-height: $headings-line-height;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: $headings-margin-bottom;

  color: $headings-color;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
}

.h2,
h2 {
  margin-top: px(8);
  margin-bottom: px(2);
}

.h1,
h1 {
  font-size: $font-size-h1;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h1 * 0.9;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-h1 * 0.8;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h1 * 0.7;
  }

  @include media-breakpoint-down(xs) {
    font-size: $font-size-h1 * 0.6;
  }
}

.h1 {
  &--large {
    font-weight: $font-weight-black;

    text-transform: uppercase;

    color: color(primary);
  }
}

.h2,
h2 {
  font-size: $font-size-h2;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h2 * 0.9;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-h2 * 0.8;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h2 * 0.7;
  }

  @include media-breakpoint-down(xs) {
    font-size: $font-size-h2 * 0.6;
  }
}

.h3,
h3 {
  font-size: $font-size-h3;
}

.h4,
h4 {
  font-size: $font-size-h4;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h4 * 0.9;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-h4 * 0.8;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h4 * 0.7;
  }

  @include media-breakpoint-down(xs) {
    font-size: $font-size-h4 * 0.6;
  }
}

.h5,
h5 {
  font-size: $font-size-h5;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h5 * 0.9;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-h5 * 0.8;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h5 * 0.7;
  }

  @include media-breakpoint-down(xs) {
    font-size: $font-size-h5 * 0.6;
  }
}

.h6,
h6 {
  font-size: $font-size-h6;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-h6 * 0.9;
  }

  @include media-breakpoint-down(md) {
    font-size: $font-size-h6 * 0.8;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-h6 * 0.7;
  }

  @include media-breakpoint-down(xs) {
    font-size: $font-size-h6 * 0.6;
  }
}

// Texts
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin;
  font-size: $font-size-lg;
  font-weight: $paragraph-font-weight;
  color: color(support, darkest);

  &.lead {
    margin-bottom: $paragraph-lead-margin-bottom;

    font-size: $paragraph-lead-font-size;
    font-weight: $paragraph-lead-font-weight;
    line-height: 1.3;
  }

  &.small {
    font-size: $font-size-xs;
  }

  &.light {
    color: color(support, dark);
  }

  &.large {
    font-size: $font-size-xl;
  }
}

strong,
b {
  font-weight: $font-weight-semibold;
}

a {
  font-weight: $font-weight-bold;
  text-decoration: none;

  color: color(support, darkest);
  border-bottom: 1px solid color(primary);

  @include transition(color);

  &:hover {
    color: color(primary);
  }
}

// Lists
ul,
ol {
  margin-top: 0;
  margin-bottom: 0;

  padding-left: 0;

  list-style: none;

  li {
    position: relative;

    padding-left: 20px;

    ul,
    ol {
      margin-top: $list-item-margin;
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      left: 0;

      border-color: color(primary);
      display: block;
    }
  }
}

ul {
  margin-bottom: px(2);
  li {
    padding-left: px(2);

    padding-left: px(3);

    color: color(support, darkest);

    &::before {
      $dot-size: 6px;

      top: calc((1em * #{$line-height-base}) / 2 - #{$dot-size} / 2);

      width: $dot-size;
      height: $dot-size;

      background-color: color(black);
      border: 1px solid transparent;
      border-radius: 50%;

      content: '';
    }

    a {
      color: color(secondary);
      text-decoration: none;
      border: none;
    }

    ul li::before {
      background: transparent;
      border-color: color(secondary);
    }
  }
}

ol {
  counter-reset: counter;

  > li {
    padding-left: px(3);

    color: color(secondary);
    font-weight: $font-weight-semibold;

    counter-increment: counter;

    &::before {
      font-size: $font-size-xxs;

      top: 4px;

      content: counter(counter);
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: color(secondary);
      text-decoration: none;
      border: none;
    }
  }
}

// Blockquote
blockquote {
  margin: px(3) px(2) !important;

  color: color(primary);
  font-size: $font-size-h3;

  quotes: '\201C''\201D''\2018''\2019';

  p {
    margin: 0 0 px(3) 0;

    font-weight: bold;
    line-height: 40px;

    &::before,
    &::after {
      display: inline;
    }

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }
}
