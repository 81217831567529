@import '@assets/styles/abstracts';

body {
  background-color: color(background);
}

.Toastify__toast {
  &--success {
    background: color(secondary, dark);
  }
}

.shareaholic-canvas {
  z-index: 9998;
}
